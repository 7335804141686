<template>
<section class="section section-gray">
  <div class="container">
    <h3 class="section-title m-bottom">Recent Members</h3>
    <div class="recent-members" v-if="members">
      <VueSlickCarousel :dots="true" :arrows="false" v-bind="slickOptions">
        <div class="members-inner" v-for="member of members" :key="member.id">
          <div class="member">
            <div class="avatar">
              <img :src="member.photo_url" :alt="member.name">
            </div>
            <h4 class="name">{{ member.name}}</h4>
            <div class="member-designation">{{ member.occupation }}</div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</section>
</template>

<script>
import ListMembers from '@/gql/ListMembers.gql';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  apollo: {
    members: ListMembers,
  },
  name: 'members',
  components: {
    VueSlickCarousel,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slickOptions: {
        autoplay: true,
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 500,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
.members-inner {
    height: 250px;
}
.member {
  text-align: center;
  padding: 0 15px;
}
.member .avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto 20px;
}

.member .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.member h4.name {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 5px;
}
.member-designation {
  font-weight: 500;
  font-size: 16px;
}
</style>
