<template>
  <section class="section section-navy">
    <div class="container">
      <h3 class="section-title m-bottom">Perspectives</h3>
      <div id="testimonials" class="testimonial-carousel" v-if="testimonials">
        <VueSlickCarousel :dots="true" :arrows="false" v-bind="slickOptions">
          <div class="testimonial-inner" v-for="testimonial of testimonials" :key="testimonial.id">
            <div class="testimonial-card">
              <div class="testimonial-content">
                <div class="quote-img">
                  <img src="images/quote.svg" alt="Quote" />
                </div>
                <p class="desc">{{ testimonial.message }}</p>
                <div class="testimonial-avatar">
                  <img :src="testimonial.person.photo_url" alt="avatar" />
                </div>
                <div class="testimonial-user">
                  <h5 class="user-name">{{ testimonial.person.name }}</h5>
                  <span class="designation">{{
                    testimonial.person.occupation
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import ListTestimonials from '@/gql/ListTestimonials.gql';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  apollo: {
    testimonials: ListTestimonials,
  },
  name: 'testimonials',
  components: {
    VueSlickCarousel,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slickOptions: {
        autoplay: true,
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>
