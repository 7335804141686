<template>
  <section class="key-metrics">
    <div class="container">
      <div class="metrics-inner">
        <div class="stats">
          <h4>{{ memberCount }}</h4>
          <h5>Members</h5>
        </div>
        <div class="stats">
          <h4>{{ networkCount }}</h4>
          <h5>Networks</h5>
        </div>
        <div class="stats">
          <h4>{{ cityCount }}</h4>
          <h5>Cities</h5>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import GetMetrics from '@/gql/GetMetrics.gql';

export default {
  apollo: {
    cityCount: GetMetrics,
    networkCount: GetMetrics,
    memberCount: GetMetrics,
  },
};
</script>
