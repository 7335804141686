<template>
  <section class="section section-gray" id="events"  v-if="hasData">
    <div class="container">
      <h3 class="section-title">Events</h3>
      <div class="events-list">
        <VueSlickCarousel :dots="true" :arrows="false" v-bind="slickOptions">
          <div class="events-inner" v-for="(event, index) of events" :key="index">
            <cs-event-card
              :picture="event.photo_url"
              :title="event.title"
              :datetime="formatDate(event.starts_at)"
              :location="`${event.location}, ${event.city.name}`"
              :tags="event.tags"
              :price="event.price == 0 ? `Free`: `${event.currency}${event.price}`"
              hide-buttons>
            </cs-event-card>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>

</template>
<script>
import ListEvents from '@/gql/ListEvents.gql';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  apollo: {
    events: ListEvents,
  },
  computed: {
    hasData() {
      return this.events && this.events.length;
    },
  },
  methods: {
    formatDate(datetime) {
      return new Date(datetime);
    },
  },
  name: 'events',
  components: {
    VueSlickCarousel,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slickOptions: {
        autoplay: true,
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 500,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
.event-item
{
  flex: 0 0 100%;
  max-width: 300px;
}

.events-inner .cs-event-card {
  border: none;
}
</style>
