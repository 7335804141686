<template>
  <div class="">
    <app-site-header />
    <app-home-hero />
    <app-home-metrics />
    <app-home-circle />
    <app-home-benefits />
    <!-- <app-home-features /> -->
    <app-home-cities />
    <app-home-networks />
    <app-home-events />
    <app-home-thoughts />
    <app-home-members />
    <app-home-testimonials />
    <app-home-values />
    <app-home-podcast />
    <!-- <app-home-articles /> -->
    <app-home-pricing />
    <!-- <app-home-invite /> -->
    <app-site-footer />
  </div>
</template>

<script>
import AppSiteHeader from '@/components/site/Header.vue';
import AppSiteFooter from '@/components/site/Footer.vue';
import AppHomeBenefits from '@/components/home/Benefits.vue';
import AppHomeEvents from '@/components/home/Events.vue';
import AppHomeHero from '@/components/home/Hero.vue';
// import AppHomeInvite from '@/components/home/Invite.vue';
import AppHomeMetrics from '@/components/home/Metrics.vue';
import AppHomeNetworks from '@/components/home/Networks.vue';
import AppHomeMembers from '@/components/home/Members.vue';
import AppHomeCities from '@/components/home/Cities.vue';
import AppHomeTestimonials from '@/components/home/Testimonials.vue';
import AppHomeCircle from '@/components/home/Circle.vue';
// import AppHomeFeatures from '@/components/home/Features.vue';
import AppHomePodcast from '@/components/home/Podcast.vue';
// import AppHomeArticles from '@/components/home/Articles.vue';
import AppHomePricing from '@/components/home/Pricing.vue';
import AppHomeThoughts from '@/components/home/Thoughts.vue';
import AppHomeValues from '@/components/home/Values.vue';

export default {
  components: {
    AppSiteHeader,
    AppSiteFooter,
    AppHomeBenefits,
    AppHomeEvents,
    AppHomeHero,
    // AppHomeInvite,
    AppHomeMetrics,
    AppHomeNetworks,
    AppHomeCities,
    AppHomeMembers,
    AppHomePodcast,
    // AppHomeArticles,
    AppHomePricing,
    AppHomeThoughts,
    AppHomeValues,
    AppHomeTestimonials,
    AppHomeCircle,
    // AppHomeFeatures,
  },
};
</script>

<style lang="css" scoped>
</style>
