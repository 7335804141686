<template>
  <section class="section section-gray podcasts" id="podcast" v-if="hasData">
   <div class="container">
     <h3 class="section-title pt-10 m-bottom">Leaders.org Podcast</h3>

     <div v-if="episodes">
       <div class="row" v-for="(episode, index) of episodes" :key="index">
          <podcast-item
            :podcast="episode"
            :count="episodes.length"
          ></podcast-item>
       </div>
     </div>
   </div>
 </section>
</template>
<script>
import ListPodcastEpisodes from '@/gql/ListPodcastEpisodes.gql';
import PodcastItem from './PodcastItem.vue';

export default {
  components: { PodcastItem },
  apollo: {
    episodes: ListPodcastEpisodes,
  },
  computed: {
    hasData() {
      return this.episodes && this.episodes.length;
    },
  },
};
</script>
<style scoped>

.podcast-card {
  height: 100%;
}

.podcast-link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  height: 100%;
}

.podcast-cover {
  max-width: 200px;
  width: 100%;
  height: 100%;
  position: relative;
}

.podcast-cover > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}

.podcast-card .play-icon {
  position: absolute;
  width: 48px;
  height: 48px;
  background: #1F6DFF;
  border-radius: 48px;
  right: -15px;
  bottom: -15px;
  text-align: center;
  padding: 12px 0 12px 5px;
}

.podcast-card .play-icon img {
  text-align: center;
}

.podcast-content {
  padding: 20px;
  color: #1B2633;
}

.podcast-date {
  text-transform: uppercase;
}
.podcast-date, .podcast-content .featuring {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 5px;
}

.podcast-content h4 {
  font-weight: 800;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 10px;
}

</style>
