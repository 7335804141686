<template>
  <section class="section section-gray networks" id="networks">
    <div class="container">
      <h3 class="section-title">Networks</h3>
      <p class="section-desc mb-60">Our global networks bring members together based on a common role, industry, alma-mater, challenge, or interest.</p>

      <div class="row" v-if="networks">

        <div class="col-md-6 col-lg-4 col-xl-2" v-for="network of networks" :key="network.id">
          <div class="network-card">
            <a href="#">
              <span class="ring"></span>
              <div class="network-card-inner">
                <h4>{{ network.display_name }}</h4>
                <h5>{{ network.display_member_count}} members</h5>
              </div>
            </a>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>
<script>
import ListNetworks from '@/gql/ListNetworks.gql';

export default {
  apollo: {
    networks: ListNetworks,
  },
};
</script>
