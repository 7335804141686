<template>
  <div v-bind:class= " (count == 0) ? 'col-xl-6':'col-xl-12'">
    <div class="podcast-item" v-if="podcast" @click.stop="onOpenPodcast">
      <div class="image-box">
        <img
          v-if="podcast.image_url"
          :src="podcast.image_url"
        />
        <img v-else src="@/assets/img/podcast.png" />
        <a @click.stop="onOpenPodcast" class="play-icon"
          ><div class="icon-circle text-white mr-2">
            <i class="cs-icons-play-filled"></i>
          </div>
        </a>
      </div>

      <div class="podcast-box">
        <div v-if="podcast.created_at" class="cs-textstyle-paragraph-small-bold date">
          {{ $moment(podcast.created_at).format('MMM DD, YYYY') }}
        </div>
        <div class="cs-textstyle-section-heading title">
          {{ podcast.title }}
        </div>
        <div class="cs-textstyle-paragraph description" v-html="podcast.description">
        </div>
        <div class="cs-textstyle-paragraph-small duration" v-if="podcast.duration">
          {{duration}} mins
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    podcast: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onOpenPodcast() {
      window.open(this.podcast.enclosure_url, '_blank');
    },
  },
  computed: {
    duration() {
      return (new Date(Date.UTC(0, 0, 0, 0, 0, 0, this.podcast.duration * 1000))).getUTCMinutes();
    },
  },
};
</script>

<style scoped>
.play-icon {
  position: absolute;
  bottom: -18px;
  right: -18px;
}
.podcast-item{
  cursor: pointer;
  background-color: #0E1D39;
  display: flex;
  flex-direction: row;
  flex-wrap: inherit;
  border-radius: 10px;
  margin-bottom: 30px;
}
.image-box img {
  border-radius: 10px;
  width: 206px;
  height: 100%;
  object-fit: cover;
}
.single-line-paragraph {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.icon-circle {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: var(--cs-primary-base);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 2px 0px 0px 1px;
  color: var(--cs-gray-00);
}
.image-box {
  position: relative;
}

.podcast-box {
  padding: 24px 24px;
}

.image-box a {
  position: absolute;
  right: -20px;
  bottom: -10px;
}

.date {
  text-transform: uppercase;
  color: var(--cs-gray-01);
  margin-bottom: 6px;
}

.title {
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .description {
    margin-top: 3px;
  }
}

@media (max-width: 767px) {
  .image-box {
    width: 100%;
  }
  .image-box img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  .podcast-episode {
    margin-bottom: 20px;
    padding-right: 0px;
  }
  .description {
    margin-top: 1px;
  }
  .podcast-item {
    flex-direction: column;
  }
}
</style>
